import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "../pages/home";
import Principles from "../pages/principles";
import About from "../pages/about";
import Contact from "../pages/contact";
//import Login from "../pages/login";
//import SignUp from "../pages/signup";
import WhyIslamicWill from "../pages/why";

import { AnimatePresence } from "framer-motion";
function AnimatedRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait" initial={false}>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/principles" element={<Principles />} />
        <Route path="/about" element={<About />} />
        {/* <Route path="/login" element={<Login />} /> */}
        {/* <Route path="/signup" element={<SignUp />} /> */}
        <Route path="/why" element={<WhyIslamicWill />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
