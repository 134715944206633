import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDW6_C4R9ttD6pwkmi76NfYGV-OW51p-X4",
  authDomain: "islamicwill-b8411.firebaseapp.com",
  projectId: "islamicwill-b8411",
  storageBucket: "islamicwill-b8411.appspot.com",
  messagingSenderId: "815709004738",
  appId: "1:815709004738:web:994a9d97c969d085692c5b",
  measurementId: "G-F8SY88QK8S",
};
const app = initializeApp(firebaseConfig);

export default firebaseConfig;
export const auth = getAuth(app);
export const db = getFirestore(app);

//Change this config with the one after we create islamic will email
