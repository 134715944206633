import React from "react";
import mosqueIcon from "./images/mosque-icon.png";
import certifiedDocumentIcon from "./images/certifiedDocument-icon.png";
import legalDocumentIcon from "./images/legalDocument-icon.png";
import Carousel from 'react-bootstrap/Carousel';

export default function Features() {
    return (
        <Carousel className="carousel" variant="dark">
            <Carousel.Item>
                <img src={mosqueIcon} alt="Sharia Compliant" className="carousel-image"/>
                <Carousel.Caption>
                    <h2>Sharia Compliant</h2>
                    <p>
                        The decisioning engine used to create the Will has been proofread
                        and verified by Islamic Scholars to comply with the Quran and
                        Sunnah.
                    </p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
            <img src={legalDocumentIcon} alt="Legally Valid" className="carousel-image"/>
                <Carousel.Caption>
                    <h2>Legally Valid</h2>
                    <p>
                        The text in each document has been validated by a lawyer ensuring
                        that the statements are valid in New Zealand Court.
                    </p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img src={certifiedDocumentIcon} alt="Fully Customised" className="carousel-image"/>
                <Carousel.Caption>
                    <h2>Fully Customised</h2>
                    <p>
                        This is no one size fits all solution, each document is uniquely
                        created for you to ensure the future of you and your family.
                    </p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}