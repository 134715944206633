import React, { useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import firebaseConfig from "./config/firebase";
import NavBar from "./Navbar";
import Footer from "./Footer";
import AnimatedRoutes from "./components/animatedroutes";

function App() {
  useEffect(() => {
    // Initialize Firebase with your firebaseConfig object
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);
  }, []);

  return (
    <div className="app-container">
      <NavBar />
      <div className="content-container">
        <div className="content">
          <AnimatedRoutes />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
