// authUtils.js
import { auth } from "../../config/firebase";
export const handleLogout = () => {
  auth
    .signOut()
    .then(() => {
      alert("User logged out.");
    })
    .catch((error) => {});
};
