import React from "react";
import { motion } from "framer-motion";
import family from "../images/family.png";
import generous from "../images/generous.png";
import handshake from "../images/handshake.png";
import moon from "../images/moon.png";
import product from "../images/product.png";

const WhyIslamicWill = () => {
  const containerStyle = {
    display: "flex",
    alignItems: "center",
    marginBottom: "2rem", // Add padding between each container rectangle
    border: "2px solid #ccc", // Add border to draw a rectangle
    borderRadius: "15px", // Optional: Add border radius for rounded corners
    padding: "1rem", // Optional: Adjust padding to make the rectangle smaller
    width: "100%", // Set width to 100% to occupy the full width of the parent container
    maxWidth: "500px", // Set a maximum width for the container
    marginRight: "15px",
    background: "white"
  };
  const containerHoverStyle = {
    scale: 1.05, // Increase the size by 5% on hover
    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)", // Add a shadow effect on hover
  };

  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.3 } }}
      style={{ padding: "2rem" }}
    >
      <h2>Why do I need an Islamic Will?</h2>
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        {/* Container 1 */}
        <motion.div
          style={{ ...containerStyle }}
          whileHover={containerHoverStyle} // Apply the hover animations
        >
          {" "}
          <img
            src={product}
            alt="Icon 1"
            style={{ width: "100px", height: "100px", marginRight: "1rem" }}
          />
          <p style={{ textAlign: "left" }}>
            <strong>Ensuring Islamic Distribution:</strong> An Islamic will
            allows you to distribute your assets according to the principles
            outlined in Islamic law (Sharia). It ensures that your wealth is
            distributed to the rightful beneficiaries specified by Islamic
            guidelines.
          </p>
        </motion.div>
        {/* Container 2 */}
        <motion.div
          style={{ ...containerStyle }}
          whileHover={containerHoverStyle} // Apply the hover animations
        >
          {" "}
          <img
            src={handshake}
            alt="Icon 1"
            style={{ width: "100px", height: "100px", marginRight: "1rem" }}
          />
          <p style={{ textAlign: "left" }}>
            <strong>Avoiding Conflict:</strong> By clearly specifying your
            wishes in the will, you can minimize the chances of disputes and
            conflicts among family members regarding inheritance matters.
          </p>
        </motion.div>

        {/* Container 3 */}
        <motion.div
          style={{ ...containerStyle }}
          whileHover={containerHoverStyle} // Apply the hover animations
        >
          {" "}
          <img
            src={family}
            alt="Icon 1"
            style={{ width: "100px", height: "100px", marginRight: "1rem" }}
          />
          <p style={{ textAlign: "left" }}>
            <strong>Nurturing Family Bonds:</strong> An Islamic will can help in
            preserving family ties by ensuring that each member receives their
            rightful share, promoting harmony and unity.
          </p>
        </motion.div>

        {/* Container 4 */}
        <motion.div
          style={{ ...containerStyle }}
          whileHover={containerHoverStyle} // Apply the hover animations
        >
          {" "}
          <img
            src={generous}
            alt="Icon 1"
            style={{ width: "100px", height: "100px", marginRight: "1rem" }}
          />
          <p style={{ textAlign: "left" }}>
            <strong>Supporting Charity:</strong> You can also allocate a portion
            of your wealth to charitable causes, which is highly regarded in
            Islam and can bring ongoing rewards even after your passing.
          </p>
        </motion.div>

        {/* Container 5 (Centered in the bottom row) */}
        <motion.div
          style={{ ...containerStyle, justifyContent: "center" }}
          whileHover={containerHoverStyle} // Apply the hover animations
        >
          <img
            src={moon}
            alt="Icon 1"
            style={{ width: "100px", height: "100px", marginRight: "1rem" }}
          />
          <p style={{ textAlign: "left" }}>
            <strong>Fulfilling Religious Obligations:</strong> Writing an
            Islamic will is considered an essential religious duty for Muslims,
            and fulfilling it is an act of obedience to Allah.
          </p>
        </motion.div>
      </div>
      <p style={{ marginBottom: "1rem" }}>
        Remember, death is inevitable, and having a proper Islamic will in place
        ensures that your wishes are fulfilled, and your loved ones are taken
        care of in the manner prescribed by Islam.
      </p>
      <br /> {/* Add spacing after the paragraph */}
      <p
        style={{ fontWeight: "bold", fontSize: "1.5em", marginBottom: "1rem" }}
      >
        "It is not permissible for any Muslim who has something to will to stay
        for two nights without having his last will and testament written and
        kept ready with him." - <i>(Sahih al-Bukhari 2738)</i>
      </p>
    </motion.div>
  );
};

export default WhyIslamicWill;
