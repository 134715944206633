// submitForm.js

import { db } from "../../config/firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export async function handleFormSubmit(email, setShowPopup) {
  if (!email || !emailRegex.test(email)) {
    alert("Please enter a valid email.");
    return;
  }

  const waitlistRef = doc(db, "emailwaitlist", email);

  // Check if the document already exists
  const docSnapshot = await getDoc(waitlistRef);
  if (docSnapshot.exists()) {
    return false;
  }

  try {
    await setDoc(waitlistRef, { email });

    setShowPopup(false);
    return true;
  } catch (error) {
    return false;
  }
}
