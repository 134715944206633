import React from "react";
import yaserImage from "../images/yaserazfar.jpg";
import youssefImage from "../images/youssefelwakil.jpg";
import { motion } from "framer-motion";

export default function About() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="about-us"
    >
      <div>
        <h2 style={{ marginTop: "2rem", marginBottom: "0" }}>Our Story</h2>
        <div className="our-story">
          <p style={{ marginBottom: "1rem" }}>
            It all started when Yaser heard about the importance of having a
            Will in Islam in an Islamic lecture. As a young man with no
            financial assets at the time he went on to ask elders in his family
            about their Will status. He found that many had thought about
            creating a Will but the hassle of finding a lawyer, then scheduling
            a meeting and going to a lawyer, checking the distributions with
            their sheikh, and finally settling on a guarantor and witnesses was
            too much work so they kept delaying. A couple of years passed and
            University friends that met while serving the Muslims on campus,
            Youssef and Yaser noticed that an alarmingly low number of Muslims
            in New Zealand have Wills.
          </p>
          <p style={{ paddingInline: "1rem" }}>
            After looking into the process in New Zealand they found that this
            process could be simplified significantly by offering a digital
            solution. So to help the Muslims of New Zealand complete a vital
            part of their deen, Youssef and Yaser began on the journey to
            simplify the process of making sharia accepted Wills.
          </p>
        </div>
      </div>
      <h2 style={{ paddingBottom: "0", paddingTop: "1rem" }}>The Team</h2>
      <div className="team-section">
        <div className="team-container">
          <div className="team-member">
            <div className="team-member-info">
              <img
                src={yaserImage}
                alt="Yaser Azfar"
                className="team-member-image"
              />
              <h3>Yaser Azfar</h3>
            </div>
            <div className="team-member-description">
              <p>
                Yaser Azfar graduated from the University of Waikato with a
                Bachelor of Engineering (Honours) in Software Engineering in
                2021. He is a dedicated software engineer passionate about
                building scalable, user-friendly web applications. Always
                looking for ways to improve the livelihood of Muslims, Yaser is
                excited to be working on a product that really matters.
              </p>
            </div>
          </div>
          <div className="team-member">
            <div className="team-member-info">
              <img
                src={youssefImage}
                alt="Youssef Elwakil"
                className="team-member-image"
              />
              <h3>Youssef Elwakil</h3>
            </div>
            <div className="team-member-description">
              <p>
                Youssef Elwakil graduated alongside Yaser with the same degree
                of BE(Hons). Youssef has always focused on helping the Ummah, at
                University he took up the challenging role of being the
                President of the Waikato Muslim Student Association. Now a
                distinguished software engineer, he is passionate about building
                solutions to benefit the Muslim community.
              </p>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
