import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";
import { motion } from "framer-motion";

const Contact = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [errors, setErrors] = useState({}); // State to hold form validation errors
  const popupRef = useRef();
  const form = useRef();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateForm = () => {
    const { user_name, user_email, message } = form.current;
    const errors = {};

    if (!user_name.value) {
      errors.user_name = "Please enter your name";
    }

    if (!user_email.value) {
      errors.user_email = "Please enter your email";
    } else if (!validateEmail(user_email.value)) {
      errors.user_email = "Please enter a valid email address";
    }

    if (!message.value) {
      errors.message = "Please enter your message";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onClose = () => {
    setShowPopup(false);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (validateForm()) {
      setErrors({});

      emailjs
        .sendForm(
          "service_gtyrc6u",
          "template_ax1hyb7",
          form.current,
          "GDqD-DQOs4pj96DmB"
        )
        .then(
          (result) => {
            setShowPopup(true);
          },
          (error) => {}
        );
    }
  };

  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.3 } }}
    >
      <StyledContactForm>
        <div className="contact-container">
          <div className="form-container">
            <div className="header">
              <h1>Contact Us</h1>
              <p>We will be sure to get back to you!</p>
            </div>
            <form ref={form} onSubmit={sendEmail}>
              <label>Name</label>
              <input
                type="text"
                name="user_name"
                className={errors.user_name ? "error" : ""}
              />
              {errors.user_name && (
                <ErrorMessage>{errors.user_name}</ErrorMessage>
              )}
              <label>Email</label>
              <input
                type="email"
                name="user_email"
                className={errors.user_email ? "error" : ""}
              />
              {errors.user_email && (
                <ErrorMessage>{errors.user_email}</ErrorMessage>
              )}
              <label>Message</label>
              <textarea
                name="message"
                className={errors.message ? "error" : ""}
              />
              {errors.message && <ErrorMessage>{errors.message}</ErrorMessage>}
              <input type="submit" value="Send" />
            </form>
            {showPopup && (
              <div className="popup-container" ref={popupRef}>
                <div className="popup">
                  <div className="popup-content">
                    <h2>Thank you for your email!</h2>
                    <p>We will get back to you as soon as we can</p>
                    <button onClick={onClose}>Close</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </StyledContactForm>
    </motion.div>
  );
};

export default Contact;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 0.5rem;
`;

// Rest of the code remains unchanged

// Styles
const StyledContactForm = styled.div`
  width: 50%;
  min-width: 350px;
  max-width: 600px;
  margin: 0 auto; /* Center the form horizontally */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  text-align: center;

  @media (max-width: 768px) {
    width: 80%;
    margin-left: auto;
    margin-right: auto; /* Center the form horizontally */
    text-align: center;
    transform: translateY(0%);
  }
  h1 {
    margin-top: 2rem; /* Add margin to the top of the heading */
  }

  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;

    input {
      width: 100%;
      height: 35px;
      padding: 7px;
      outline: none;
      border-radius: 20px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid #324b77;
      }
    }

    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 100px;
      min-height: 100px;
      padding: 7px;
      outline: none;
      border-radius: 20px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid #324b77;
      }
    }

    label {
      margin-top: 1rem;
    }

    input[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      background: #324b77;
      color: white;
      border: none;
    }
  }
`;
