import { motion } from "framer-motion"; // Updated import for AnimatePresence

export default function Principles() {
  const containerStyle = {
    display: "flex",
    marginBottom: "2rem", // Add padding between each container rectangle
    border: "2px solid #ccc", // Add border to draw a rectangle
    borderRadius: "15px", // Optional: Add border radius for rounded corners
    padding: "1rem", // Optional: Adjust padding to make the rectangle smaller
    width: "100%", // Set width to 100% to occupy the full width of the parent container
    maxWidth: "500px", // Set a maximum width for the container
    marginRight: "15px",
    background: "white",
  };
  const containerHoverStyle = {
    scale: 1.05, // Increase the size by 5% on hover
    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)", // Add a shadow effect on hover
  };

  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.3 } }}
      style={{ padding: "2rem" }}
    >
      <h2>Principles</h2>
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        {/* Container 1 */}
        <motion.div
          style={{ ...containerStyle }}
          whileHover={containerHoverStyle} // Apply the hover animations
        >
          <p style={{ textAlign: "left" }}>
            <strong>How It Works:</strong>
            <p>
              We have created an easy form based
              questionnaire that you can fill out. After filling it out, our
              system will use your input and our reviewed decisioning engine to
              create your Will. The Will has been verified by both lawyers and
              Sheikhs to ensure that it covers all Islamic and New Zealand law
              requirements.
            </p>
          </p>
        </motion.div>
        {/* Container 2 */}
        <motion.div
          style={{ ...containerStyle }}
          whileHover={containerHoverStyle} // Apply the hover animations
        >
          <p style={{ textAlign: "left" }}>
            <strong>Costs:</strong>
            <p>
              To keep this service running, we have kept a small cost of $50 for
              a verified islamic Will. You can also opt-in for a small $10 a
              year payment which allows you to come back and edit your Will any
              time without having to pay for the entire service again.
            </p>
          </p>
        </motion.div>

        {/* Container 3 */}
        <motion.div
          style={{ ...containerStyle }}
          whileHover={containerHoverStyle} // Apply the hover animations
        >
          <p style={{ textAlign: "left" }}>
            <strong>Different Madh’hab:</strong>
            <p>
              Islam provides detailed inheritance laws, although some heir
              situations are not explicitly mentioned in the Quran and Sunnah.
              Muslim scholars interpret these situations to the best of their
              ability, resulting in different perspectives. However, most
              scholars agree on the majority of heir situations. If unsure, you
              can follow the majority/jumhour opinion of scholars.
            </p>
          </p>
        </motion.div>

        {/* Container 4 */}
        <motion.div
          style={{ ...containerStyle }}
          whileHover={containerHoverStyle} // Apply the hover animations
        >
          <p style={{ textAlign: "left" }}>
            <strong>Quranic Verses & Hadiths:</strong>
            <p style={{ marginBottom: "1rem" }}>
              "It is not permissible for any Muslim who has something to will to
              stay for two nights without having his Last Will and Testament
              written and kept ready with him." <i>(Sahih al-Bukhari 2738)</i>
            </p>
            <p style={{ marginBottom: "1rem" }}>
              "It has been ordained upon you, when death is near one of you,
              leaving wealth behind, to make a will in favor of parents and
              close relatives, impartially. This is incumbent upon the pious."{" "}
              <i>(Quran 2:180)</i>
            </p>
            <p>
              "Allah also says: 'When death draws near one of you… it is time to
              make a bequest.'" <i>(Quran 5:106)</i>
            </p>
          </p>
        </motion.div>
      </div>
    </motion.div>
  );
}
