import { React } from "react";
import homeImage from "../images/home-image.png";
import { motion } from "framer-motion";
import "../styles/homepage/popup.css";
import Features from "../Features";
import Reviews from "../Reviews";

export default function Home() {

  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.3 } }}
    >
      <div className="home-container">
        <div className="home-left">
          <h1>
          Create your government accepted and Sharia compliant Will
          </h1>
          <br></br>
          <button className="createWillButton">Create Will</button>
        </div>
        <div className="home-right">
          <p>
          Have your completely valid document in your hand in 15 minutes! 
          Reviewed by Islamic leaders and lawyers in New Zealand to provide an easy 
          and reliable way to create your Will that is Sharia Compliant.
          </p>
        </div>
      </div>
      <div className="home-image-container">
          <img src={homeImage} alt="Last Will" className="home-image" />
      </div>
      <div className="home-statistics">
        <div className="home-statistic">
          <h2>23</h2>
          <p>Months of development</p>
        </div>
        <div className="home-statistic">
          <h2>4</h2>
          <p>Partners</p>
        </div>
        <div className="home-statistic">
          <h2>10+</h2>
          <p>Five star reviews</p>
        </div>
      </div>
      <div className="home-container-offers">
        <div className="home-container-offers-left">
          <h2> What do we offer...</h2>
          <p> Reviewed by Islamic leaders and lawyers in New Zealand to provide an easy & reliable way to create your Will that is... </p>
          <a href="/principles">Learn more</a>
        </div>
        <div className="home-container-offers-right">
          <Features/>
        </div>
      </div>
      <div className="home-container-quote">
        <h1> Complete your Islamic obligation</h1>
        <p>In the name of God, the Most Gracious, the Most Merciful</p>
        <h2>
          “It is decreed for you: when death approaches one of you, and he
          leaves wealth, to make a testament in favour of the parents and the
          relatives, fairly and correctly—a duty upon the righteous.”
        </h2>
        <p>(Al - Baqarah - 180)</p>
      </div>
      <Reviews/>
    </motion.div>
  );
}
