import React from "react";

export default function Reviews() {
    return (
        <div className="reviews-container">
            <div className="reviews-container-left"> 
                <h2>What do our customers say about us...</h2>
            </div>
            <div className="reviews-container-right">
                <div className="reviews-container-right-left">
                    <div className="review-box">
                        <h5>
                            "It is great to have the ability to make such a vital document so easily. I am sure this will benefit a lot of people."
                        </h5>
                        <p>- Saquib</p>
                    </div>
                </div>
                <div className="reviews-container-right-right">
                </div>
            </div>
        </div>
    );
}