import { NavLink } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { auth } from "./config/firebase";
import menuIcon from "./images/menu-icon.png";
import closeIcon from "./images/close.png";
import { handleLogout } from "./components/signupcomponents/authutils";
import { handleFormSubmit } from "./components/signupcomponents/submitform";


export default function NavBar() {
  const [isActive, setIsActive] = useState(false);
  const [user, setUser] = useState(null); // State to hold the user data
  const [showSubMenu, setShowSubMenu] = useState(false); // State to control the visibility of the sub-menu
  const navRef = useRef(null);

  const [showPopup, setShowPopup] = useState(false);
  const [showPopupComplete, setShowPopupComplete] = useState(false); // State variable for showing/hiding the popup
  const [showErrorPopupComplete, setShowErrorPopupComplete] = useState(false); // State variable for showing/hiding the popup

  const [email, setEmail] = useState("");

  const popupRef = useRef();

  // Function to close the popup when clicked outside
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowPopup(false);
      setShowPopupComplete(false);
    }
  };

  // Add event listener when the component mounts
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const success = await handleFormSubmit(email, setShowPopup);
    // If handleFormSubmit was successful, set showPopupComplete to true
    if (success) {
      setShowPopupComplete(true);
    } else {
      setShowErrorPopupComplete(true);
    }
  };

  const onClose = () => {
    setShowPopupComplete(false);
    setShowErrorPopupComplete(false);
  };


  const handleClick = () => {
    setIsActive(!isActive);
  };

  const handleNavItemClick = () => {
    setIsActive(false);
  };

  const handleSubMenuClick = () => {
    setShowSubMenu(!showSubMenu); // Toggle the visibility of the sub-menu
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsActive(false);
        setShowSubMenu(false); // Close the sub-menu when clicking outside
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    // Check if the user is signed in
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user); // Update the user state with the user data
    });

    return () => {
      unsubscribe(); // Unsubscribe from the auth state listener on component unmount
    };
  }, []);

  return (
    <nav className="nav" ref={navRef}>
      <NavLink to="/" className="site-title" onClick={handleNavItemClick}>
        Will For Islam
      </NavLink>
      <ul className={isActive ? "sidebarActive" : ""}>
        <li onClick={handleNavItemClick}>
          <NavLink to="/principles">Principles</NavLink>
        </li>
        <li onClick={handleNavItemClick}>
          <NavLink to="/about">About</NavLink>
        </li>
        <li onClick={handleNavItemClick}>
          <NavLink to="/why">Why</NavLink>
        </li>
        <li onClick={handleNavItemClick}>
          <NavLink to="/contact">Contact</NavLink>
        </li>
        <button onClick={() => setShowPopup(true)} className="createWillButton">Create Will</button>
        {user ? (
          <li onClick={handleNavItemClick} className="user-profile">
            <span onClick={handleSubMenuClick}>
              {user.email}
              {showSubMenu && (
                <ul className="sub-menu">
                  <li onClick={handleLogout}>
                    <NavLink to="/" className="logout-link">
                      Profile
                    </NavLink>
                  </li>
                  {/*
                  <li onClick={handleLogout}>
                    <NavLink to="/" className="logout-link">
                      Log Out
                    </NavLink>
                  </li>
              */}
                </ul>
              )}
            </span>
          </li>
        ) : (
          <></>
          //<li onClick={handleNavItemClick}>
          //<NavLink to="/login">Login</NavLink>
          //</li>
        )}
      </ul>
      <button className="hamburger-button" onClick={handleClick}>
        <img src={menuIcon} alt="menu" />
      </button>
      {showPopup && (
        <div className="popup-container">
          <div className="popup" ref={popupRef}>
            <span className="close-icon" onClick={() => setShowPopup(false)}>
              <img src={closeIcon} alt="Close" />
            </span>
            <h2>
              We are currently building our website, leave your email here to
              get more updates on this.
            </h2>
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                value={email}
                onChange={handleInputChange}
                placeholder="Your email"
              />
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}

      {showPopupComplete && (
        <div className="popup-container">
          <div className="popup">
            <div className="popup-content">
              <h2>Thank you for joining the waitlist!</h2>
              <p>You have been successfully added to our waitlist.</p>
              <button onClick={onClose}>Close</button>
            </div>
          </div>
        </div>
      )}
      {showErrorPopupComplete && (
        <div className="popup-container">
          <div className="popup">
            <div className="popup-content">
              <h2>Uh Oh!</h2>
              <p>You have already joined the waitlist.</p>
              <button onClick={onClose}>Close</button>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}
